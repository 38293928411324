export interface IOpeningHours {
  _id: string;
  // Day between 0 and 6
  day: number;
  // Whether the current day is open
  isOpen: boolean;
  // Start opening time of the day
  start: number;
  // End opening time of the day
  end: number;
}
