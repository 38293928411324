import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthGuard } from './auth.guard';

// List of guards
const UAA_GUARDS = [AuthGuard];

@NgModule({
  imports: [CommonModule],
  providers: [...UAA_GUARDS],
})
export class GuardsModule {}
