import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IMetricsData, MetricsGranularity, MetricsType } from '../models';
import { MetricsDataUrl } from './service-urls';
import { BackendService } from './utility-services/backend.service';

@Injectable({
  providedIn: 'root',
})
export class MetricsDataService {
  constructor(private backendService: BackendService) {}

  public createMetricsData(createValues: Partial<IMetricsData>): Observable<IMetricsData> {
    return this.backendService.post<IMetricsData>({
      url: MetricsDataUrl.createMetricsData(),
      body: createValues,
    });
  }

  public generateRandomData(
    type: MetricsType,
    granularity: MetricsGranularity,
  ): Observable<IMetricsData[]> {
    return this.backendService.put<IMetricsData[]>({
      url: MetricsDataUrl.generateRandomData(),
      params: { type, granularity: granularity.toString() },
    });
  }

  public getMetricsData(
    type: MetricsType,
    granularity: MetricsGranularity,
  ): Observable<IMetricsData[]> {
    return this.backendService.get<IMetricsData[]>({
      url: MetricsDataUrl.getMetricsData(),
      params: { type, granularity: granularity.toString() },
    });
  }

  public updateMetricsData(
    metricsDataId: string,
    updateValues: Partial<IMetricsData>,
  ): Observable<IMetricsData> {
    return this.backendService.put<IMetricsData>({
      url: MetricsDataUrl.updateMetricsData(metricsDataId),
      body: updateValues,
    });
  }

  public deleteMetricsData(type: MetricsType, granularity: MetricsGranularity): Observable<void> {
    return this.backendService.delete<void>({
      url: MetricsDataUrl.deleteMetricsData(),
      params: { type, granularity: granularity.toString() },
    });
  }
}
