export enum DeviationScoreApplier {
  LOWER_THAN = 'lt',
  GREATER_THAN = 'gt',
  EQUALS = 'eq',
}

export interface IFittingDeviationScore {
  _id: string;
  __v?: number;
  // Difference to ideal demand
  demandFitting: number;
  // How to apply demandFitting to ideal demand, e.g. ">4" should be modeled as 'gt'
  operator: DeviationScoreApplier;
  // Weight for reward/penalty of objective function
  weight: number;
}
