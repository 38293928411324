import { IFittingDeviationScore } from './fitting-deviation-score.model';

export interface IFittingSlice {
  _id: string;
  __v?: number;
  // Day the slice belongs to
  day: number;
  // Start time of the slice
  startTime: number;
  // End time of the slice
  endTime: number;
  // Fitting deviation scores belonging to the slice
  deviationScores: IFittingDeviationScore[];
}
