export enum MetricsType {
  REVENUE = 'revenue',
  GUESTS = 'guests',
}

export enum MetricsGranularity {
  THIRTY = 30,
  SIXTY = 60,
}

export interface IMetricsData {
  _id: string;
  // Type of the used metric
  type: MetricsType;
  // How fine granular the metric is specified
  granularity: MetricsGranularity;
  // Day of the week to which the metrics data belongs to
  day: number;
  // The actual metrics data with the given granularity
  data: { [key: string]: number };
}

/**
 * Array of metric types
 */
export const METRIC_TYPES: MetricsType[] = Object.values(MetricsType);

/**
 * Array of metric granularities
 */
export const METRIC_GRANULARITIES = Object.values(MetricsGranularity).filter(
  (granularity: string | number) => typeof granularity === 'number',
) as number[];
