import { MetricsGranularity, MetricsType } from './metrics-data.model';

export interface IRandomOptions {
  _id: string;
  // Type of the used metric
  type: MetricsType;
  // How fine granular the metric is specified
  granularity: MetricsGranularity;
  // Lower bound for the randomly generated metrics data
  lowerBound: number;
  // Upper bound for the randomly generated metrics data
  upperBound: number;
}
