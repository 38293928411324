import {
  Directive,
  Input,
  TemplateRef,
  HostListener,
  OnInit,
  ElementRef,
  ComponentRef,
} from '@angular/core';
import { OverlayRef, Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { UaaTooltipComponent } from './uaa-tooltip.component';

@Directive({ selector: '[appTooltip]' })
export class UaaTooltipDirective implements OnInit {
  @Input('appTooltip') content?: TemplateRef<any>;
  private overlayRef?: OverlayRef;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay,
  ) {}

  public ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  public show(): void {
    if (!this.overlayRef) {
      return;
    }
    const tooltipPortal = new ComponentPortal(UaaTooltipComponent);

    const tooltipRef: ComponentRef<UaaTooltipComponent> = this.overlayRef.attach(tooltipPortal);

    tooltipRef.instance.content = this.content;
  }

  @HostListener('mouseout')
  public hide(): void {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.detach();
  }
}
