import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

// Request types
export enum RequestType {
  GET,
  POST,
  PUT,
  DELETE,
}

// Type definition for http requests
type Params = HttpParams | { [param: string]: string | string[] };

// Service that handles the requests to the API
@Injectable({ providedIn: 'root' })
export class BackendService {
  private username = `test-user`;
  private password: string | null = ``;

  constructor(private http: HttpClient) {}

  /**
   * Makes a server request
   * @param method Request method
   * @param serviceUrl Service url
   * @param body Request body
   * @param params Request params
   * @param retries Number of retries
   */
  private serverRequest<T>({
    method,
    url,
    headers,
    body = {},
    params = {},
    retries = 0,
  }: {
    method: RequestType;
    url: string;
    headers?: HttpHeaders;
    // tslint:disable-next-line:no-any
    body?: any;
    params?: Params;
    retries: number;
  }): Observable<T> {
    if (!headers) {
      headers = new HttpHeaders();
    }
    if (!headers.has('Authorization')) {
      const encoded = window.btoa(`${this.username}:${this.password}`);
      headers = headers.set('Authorization', `Basic ${encoded}`);
    }

    switch (method) {
      case RequestType.GET:
        return this.http
          .get<T>(url, {
            headers,
            params,
          })
          .pipe(retry(+retries));

      case RequestType.POST:
        return this.http.post<T>(url, body, { headers, params }).pipe(retry(+retries));

      case RequestType.PUT:
        return this.http.put<T>(url, body, { headers, params }).pipe(retry(+retries));

      case RequestType.DELETE:
        return this.http
          .delete<T>(url, {
            headers,
            params,
          })
          .pipe(retry(+retries));

      default:
        break;
    }
    return EMPTY;
  }

  /**
   * Get request
   * @param url URL
   * @param params Params
   * @param retries Number of retries
   * @returns Response as Observable
   */
  public get<T>({
    url,
    params = {},

    retries = 0,
  }: {
    url: string | (string | number)[];
    params?: Params;

    retries?: number;
  }): Observable<T> {
    // The route interceptor will replace the '$' by the backend url
    return this.serverRequest<T>({
      method: RequestType.GET,
      url: this.joinUrl(url),
      params,

      retries,
    });
  }

  /**
   * Post request
   * @param url URL
   * @param headers Http headers
   * @param body Body of request
   * @param params Params of request
   * @param retries Number of retries
   * @returns Response as Observable
   */
  public post<T>({
    url,
    headers,
    body = {},
    params,
    retries = 0,
  }: {
    url: string | (string | number)[];
    // tslint:disable-next-line:no-any
    body?: any;
    headers?: HttpHeaders;
    params?: Params;
    retries?: number;
  }): Observable<T> {
    // The route interceptor will replace the '$' by the backend url
    return this.serverRequest<T>({
      method: RequestType.POST,
      url: this.joinUrl(url),
      headers,
      body,
      params,
      retries,
    });
  }

  /**
   * Put request
   * @param url URL
   * @param headers Http headers
   * @param body Body of request
   * @param params Params of request
   * @param retries Number of retries
   * @returns Response as Observable
   */
  public put<T>({
    url,
    headers,
    body = {},
    params = {},
    retries = 0,
  }: {
    url: string | (string | number)[];
    headers?: HttpHeaders;
    // tslint:disable-next-line:no-any
    body?: any;
    params?: Params;
    retries?: number;
  }): Observable<T> {
    // The route interceptor will replace the '$' by the backend url
    return this.serverRequest<T>({
      method: RequestType.PUT,
      url: this.joinUrl(url),
      headers,
      body,
      params,
      retries,
    });
  }

  /**
   * Delete request
   * @param url URL
   * @param headers Http headers
   * @param params Params
   * @param retries Number of retries
   * @returns Response as Observable
   */
  public delete<T>({
    url,
    headers,
    params = {},
    retries = 0,
  }: {
    url: string | (string | number)[];
    headers?: HttpHeaders;
    params?: Params;
    retries?: number;
  }): Observable<T> {
    // The route interceptor will replace the '$' by the backend url
    return this.serverRequest<T>({
      method: RequestType.DELETE,
      url: this.joinUrl(url),
      headers,
      params,
      retries,
    });
  }

  /**
   * Joins url if provided as string
   * @param url Url in string or array format
   * @returns Url as string
   */
  private joinUrl(url: string | (string | number)[]): string {
    if (Array.isArray(url)) {
      return '$' + url.join('/');
    } else {
      return '$' + url;
    }
  }

  /**
   * Sets the backend password
   */
  public setPassword(pwd: string | null): void {
    this.password = pwd;
  }
}
