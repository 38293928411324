import { MetricsGranularity, MetricsType } from './metrics-data.model';
import { IPauseSystem } from './pause-system.model';
import { IPauseRule } from './pause-rule.model';
import { LpModel } from './lp-model-options.model';

export interface IDemandResult {
  ideal: number;
  min: number;
  max: number | null;
  actual: number;
  slotTime: number;
}

export interface IPauseResult {
  start: number;
  end: number;
  activePauseSystem?: IPauseSystem;
  activePauseRules?: IPauseRule[];
}

export interface IShiftResult {
  start: number;
  end: number;
  pauses?: IPauseResult[];
}

export interface IPlanResultRoster {
  date: Date;
  demand: IDemandResult[];
  shifts?: IShiftResult[];
}

export type SolutionStatus = 'optimal' | 'feasible';

export interface IPlanResult {
  solutionStatus?: SolutionStatus;
  objectiveValue?: number;
  roster: IPlanResultRoster[];
  plannedHours?: number;
}

export interface IPlanParams {
  startTime: string | Date;
  endTime: string | Date;
  type: MetricsType;
  metricGranularity: MetricsGranularity;
  shiftGranularity?: number;
  maxHours?: number;
  strategyId?: string;
  solver?: string;
  maxTime?: number;
  lpModel: LpModel;
  pauseFastMode: boolean;
}

export class SolverType {
  private constructor(public value: string, public label: string) {}

  static readonly COINOR = new SolverType(
    'ssa-optimizer-core-coinor',
    'COIN-OR Branch-and-Cut MIP Solver',
  );
  static readonly CPLEX = new SolverType('ssa-optimizer-core-cplex', 'IBM CPLEX Optimizer');
  static readonly GLPK = new SolverType('ssa-optimizer-core-glpk', 'GNU Linear Programming Kit');
  static readonly GUROBI = new SolverType('ssa-optimizer-core-gurobi', 'Gurobi');

  /**
   * Getter for the solver types.
   * @returns All defined solver types as an array.
   */
  static getTypesAsArray(): SolverType[] {
    return Object.values(SolverType).filter((type) => type instanceof SolverType);
  }
}
