<div class="uaa-demand-chart__container">
  <svg [attr.width]="graphWidth" [attr.height]="graphHeight + deviationScoreWeightIndicatorHeight">
    <!-- y-axis scale -->
    <g>
      <ng-container *ngFor="let i of yScale">
        <text
          style="font-weight: bold"
          x="8"
          [attr.y]="graphHeight + fontSize / 4 - yFraction * yFractionMultiplicator * i"
        >
          {{ i }}
        </text>
        <line
          [attr.x1]="yScaleHorizontalMargin"
          [attr.y1]="graphHeight - yFraction * yFractionMultiplicator * i"
          [attr.x2]="graphWidth"
          [attr.y2]="graphHeight - yFraction * yFractionMultiplicator * i"
          [attr.stroke-width]="rowLineSize"
          [attr.stroke]="rowLineColor"
        ></line>
      </ng-container>
    </g>

    <!-- group per day of the chart -->
    <g [attr.transform]="'translate(' + yAxisMargin + ')'">
      <ng-container *ngFor="let day of viewDemandData; let dayIndex = index">
        <!-- chart header -->
        <g [attr.transform]="'translate(' + day.xAxisDayOffset + ')'">
          <ng-container *ngFor="let demand of day.demand; let i = index">
            <rect
              [attr.x]="colWidth * dateColumnWidthMultiplier - 0.5 * colWidth + i * colWidth"
              [attr.width]="colWidth"
              [attr.y]="1"
              [attr.height]="chartHeaderHeight - chartHeaderMargin"
              [attr.fill]="chartHeaderColor"
              [attr.stroke]="colLineColor"
              stroke-width="2"
            ></rect>
            <text
              [attr.x]="
                colWidth * dateColumnWidthMultiplier - 0.5 * colWidth + i * colWidth + colWidth / 8
              "
              [attr.y]="chartHeaderHeight / 2"
              [attr.font-size]="fontSize"
              font-weight="bold"
            >
              {{ demand.slotTimeString }}
            </text>
          </ng-container>
        </g>

        <g [attr.transform]="'translate(' + day.xAxisDayOffset + ')'">
          <!-- x-axis bars -->
          <ng-container *ngFor="let demand of day.columnDividerArray; let i = index">
            <line
              [attr.x1]="colWidth * dateColumnWidthMultiplier - 0.5 * colWidth + i * colWidth"
              [attr.y1]="0"
              [attr.x2]="colWidth * dateColumnWidthMultiplier - 0.5 * colWidth + i * colWidth"
              [attr.y2]="graphHeight"
              [attr.stroke]="colLineColor"
              [attr.stroke-width]="colLineSize"
            ></line>
          </ng-container>

          <!-- date string -->
          <text
            style="fill: black; font-weight: bold; font-size: 1.2rem"
            [attr.x]="0"
            [attr.y]="chartHeaderHeight / 2"
          >
            {{ day.dateString }}
          </text>
          <text
            style="fill: black; font-weight: 400; font-size: 0.8rem"
            [attr.x]="0"
            [attr.y]="chartHeaderHeight / 2 + fontSize * 1.15"
          >
            {{ day.localizedWeekday }}
          </text>
          <text
            *ngIf="day.slices"
            style="fill: black; font-weight: 400; font-size: 0.8rem"
            [attr.x]="0"
            [attr.y]="chartHeaderHeight / 2 + fontSize * 1.15 + fontSize * 0.9"
          >
            Slices: {{ day.slices.length }}
          </text>

          <!-- graphical elements for data visualization -->
          <g [attr.transform]="'translate(' + colWidth * dateColumnWidthMultiplier + ')'">
            <!-- actual demand bar chart -->
            <ng-container *ngFor="let demand of day.demand; let i = index">
              <rect
                [attr.width]="barWidth"
                [attr.rx]="barCornerRadius"
                [attr.ry]="barCornerRadius"
                [attr.height]="demand.actual * yFraction * yFractionMultiplicator"
                [attr.x]="i * colWidth"
                [attr.y]="graphHeight - demand.actual * yFraction * yFractionMultiplicator"
                [attr.fill]="demand.barColor"
              ></rect>
            </ng-container>

            <!-- actual demand line chart -->

            <polyline
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              [attr.stroke]="lineColor"
              [attr.stroke-width]="lineStrokeWidth"
              [attr.points]="day.linePointsString"
            ></polyline>

            <ng-container *ngFor="let demand of day.demand; let i = index">
              <circle
                *ngIf="demand.ideal === demand.actual || demand.actual > demand.ideal"
                [attr.cx]="i * colWidth + barWidth / 2"
                [attr.cy]="graphHeight - demand.ideal * yFraction * yFractionMultiplicator"
                [attr.r]="linePointSize"
                [attr.fill]="linePointColor"
              ></circle>
            </ng-container>

            <!-- deviation score actually applied weight indicator -->
            <ng-container *ngIf="fittingStrategyData">
              <ng-container *ngFor="let demand of day.demand; let i = index">
                <text
                  *ngIf="demand.showDeviationScoreWarning"
                  [attr.x]="i * colWidth - 5"
                  [attr.y]="graphHeight + deviationScoreWeightIndicatorHeight * 0.8"
                  style="font-weight: 700; font-size: 1.2rem; fill: #222222"
                >
                  ⚠
                </text>
              </ng-container>
            </ng-container>

            <!-- bar chart difference text highlight -->
            <g *ngFor="let demand of day.demand; let i = index">
              <text
                [attr.x]="i * colWidth - fontSize / 2"
                [attr.y]="
                  graphHeight -
                  (demand.difference! > 0 ? demand.actual : demand.ideal) *
                    yFraction *
                    yFractionMultiplicator -
                  fontSize * 1.25
                "
                [attr.fill]="demand.barColor"
                *ngIf="demand.differenceLabel"
                [attr.font-size]="fontSize * 1.25"
              >
                {{ demand.differenceLabel }}
              </text>
            </g>

            <!-- applied strategies slices -->
            <ng-container *ngIf="fittingStrategyData">
              <!-- Draw all slice rectangles before all slice dividers to prevent styling issues related to z-indexing -->
              <ng-container *ngFor="let slice of day.slices; let i = index">
                <rect
                  [attr.x]="slice.x! - colWidth / 2"
                  [attr.width]="slice.width"
                  [attr.height]="graphHeight"
                  [attr.y]="0"
                  [attr.fill]="sliceColor"
                  [attr.fill-opacity]="'0'"
                  class="uaa-demand-chart__slice"
                  [class.selected]="slice._id === selectedSlice?._id"
                  (click)="toggleSelectSlice(slice, i, day.localizedWeekday!)"
                ></rect>
              </ng-container>
              <ng-container *ngFor="let slice of day.slices; let i = index">
                <line
                  *ngIf="!slice.isLastInDay && day.slices!.length > 1"
                  [attr.x1]="slice.x! + slice.width! - colWidth / 2"
                  [attr.y1]="0"
                  [attr.x2]="slice.x! + slice.width! - colWidth / 2"
                  [attr.y2]="graphHeight + deviationScoreWeightIndicatorHeight"
                  [attr.stroke-width]="sliceBorderWidth"
                  [attr.stroke]="sliceBorderColor"
                ></line>
              </ng-container>
            </ng-container>
          </g>
        </g>
      </ng-container>
    </g>
  </svg>
</div>

<div class="uaa-demand-chart__slice-details__container" *ngIf="fittingStrategyData">
  <ng-container *ngIf="!selectedSlice">
    <span>Klicke auf ein Slice um Details zu sehen</span>
  </ng-container>

  <ng-container *ngIf="selectedSlice">
    <h3 class="uaa-demand-chart__slice-details__heading">
      {{ fittingStrategyData.name }} | {{ selectedSlice.weekday }} ({{
        selectedSlice.startTimeString
      }}
      - {{ selectedSlice.endTimeString }}) | {{ selectedSlice.name }}
    </h3>
    <button
      mat-stroked-button
      class="uaa-demand-chart__slice-details__button"
      routerLink="/besetzungsstrategien/{{ fittingStrategyData.type }}/{{
        fittingStrategyData.granularity
      }}"
    >
      <mat-icon>settings</mat-icon>Zur Besetzungsstrategie
    </button>
    <table
      mat-table
      [dataSource]="selectedSlice.deviationScores"
      class="uaa-demand-chart__slice-details"
    >
      <ng-container matColumnDef="deviation">
        <th mat-header-cell *matHeaderCellDef class="uaa-demand-chart__slice-details__column">
          Abweichung
        </th>
        <td mat-cell *matCellDef="let score" class="uaa-demand-chart__slice-details__column">
          {{ mapOperatorToSign(score.operator) }} {{ score.demandFitting }}
        </td>
      </ng-container>

      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef class="uaa-demand-chart__slice-details__column">
          Gewichtung
        </th>
        <td
          mat-cell
          *matCellDef="let score"
          [style]="'background-color: ' + mapAppliedWeightToColor(score.weight)"
          class="uaa-demand-chart__slice-details__column"
        >
          {{ score.weight }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="uaa-demand-chart__slice-details__row"
      ></tr>
    </table>
  </ng-container>
</div>
