import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IFittingDeviationScore,
  IFittingSlice,
  IFittingStrategy,
  MetricsGranularity,
  MetricsType,
} from '../models';
import { IFittingDeviationScoreColorMapping } from '../models/fitting-deviation-score-color-mapping.model';

import { FittingStrategyUrl } from './service-urls';
import { BackendService } from './utility-services/backend.service';

export interface IStrategyQueryParams {
  type?: MetricsType;
  granularity?: MetricsGranularity;
  day?: number;
}

export interface IFittingSliceDefaultScores {
  minScore: number;
  maxScore: number;
  weight: number;
}

@Injectable({
  providedIn: 'root',
})
export class FittingStrategyService {
  private strategies: BehaviorSubject<IFittingStrategy[] | null> = new BehaviorSubject<
    IFittingStrategy[] | null
  >(null);
  public $strategies: Observable<IFittingStrategy[] | null> = this.strategies.asObservable();

  constructor(private backendService: BackendService) {}

  /**
   * Returns an array of mapping entries for fittingStrategyDeviationScores and
   * their associated colors.
   */
  public static getDeviationScoreColorMapping(): IFittingDeviationScoreColorMapping[] {
    return [
      { weight: -2, color: 'rgba(252, 84, 91)' },
      { weight: -1, color: 'rgba(238, 178, 58)' },
      { weight: 0, color: 'rgba(238, 246, 252)' },
      { weight: 1, color: 'rgba(193, 242, 231)' },
      { weight: 2, color: 'rgba(68, 194, 160)' },
    ];
  }

  // STRATEGY
  /**
   * Fetches all strategies from backend and emits them to BehaviourSubject for it.
   * Note: Will always reload the data even if data is already present. Currently
   * not necessary to implement reload prevention.
   */
  public getAllStrategies(): void {
    this.backendService
      .get<IFittingStrategy[]>({
        url: FittingStrategyUrl.getStrategies(),
      })
      .subscribe((strategies) => {
        this.strategies.next(strategies);
      });
  }

  public getStrategies(params?: IStrategyQueryParams): Observable<IFittingStrategy[]> {
    return this.backendService.get<IFittingStrategy[]>({
      url: FittingStrategyUrl.getStrategies(),
      params: {
        type: params?.type || '',
        granularity: params?.granularity?.toString() || '',
        day: params?.day?.toString() || '',
      },
    });
  }

  public getStrategyById(strategyId: string): Observable<IFittingStrategy> {
    return this.backendService.get<IFittingStrategy>({
      url: FittingStrategyUrl.getStrategyById(strategyId),
    });
  }

  public createSliceForStrategy(
    strategyId: string,
    createValues: Partial<IFittingSlice>,
    deviationScoreValues: IFittingSliceDefaultScores,
  ): Observable<IFittingStrategy> {
    return this.backendService.post<IFittingStrategy>({
      url: FittingStrategyUrl.createSliceForStrategy(strategyId),
      body: { createValues, deviationScoreValues },
    });
  }

  public deleteSliceForStrategy(strategyId: string, sliceId: string): Observable<IFittingStrategy> {
    return this.backendService.delete<IFittingStrategy>({
      url: FittingStrategyUrl.deleteSliceOfStrategy(strategyId, sliceId),
    });
  }

  // SLICE
  public updateSlice(
    sliceId: string,
    updateValues: Partial<IFittingSlice>,
  ): Observable<IFittingSlice> {
    return this.backendService.put<IFittingSlice>({
      url: FittingStrategyUrl.updateSlice(sliceId),
      body: updateValues,
    });
  }

  // DEVIATION SCORE
  public updateDeviationScore(
    deviationScoreId: string,
    updateValues: Partial<IFittingDeviationScore>,
  ): Observable<IFittingDeviationScore> {
    return this.backendService.put<IFittingDeviationScore>({
      url: FittingStrategyUrl.updateDeviationScore(deviationScoreId),
      body: updateValues,
    });
  }
}
