import { MetricsGranularity, MetricsType } from './metrics-data.model';
import { IRuleSet } from './rule-set.model';

export interface IRuleSetGroup {
  _id: string;
  // Displayed name of the rule set group
  name: string;
  // Metrics Type that is used for all rule sets in this group
  type: MetricsType;
  // Granularity that belongs to the metric
  granularity: MetricsGranularity;
  // All rule sets that are associated with this group
  ruleSets: IRuleSet[];
}
