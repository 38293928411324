import { IFittingSlice } from './fitting-slice.model';
import { MetricsGranularity, MetricsType } from './metrics-data.model';

export interface IFittingStrategy {
  _id: string;
  __v?: number;
  // Name of the strategy
  name: string;
  // Metrics type the strategy belongs to
  type: MetricsType;
  // Metrics granularity the strategy belongs to
  granularity: MetricsGranularity;
  // Slices of the strategy
  slices: IFittingSlice[];
}
