/**
 * Reasons of errors
 */
export enum UAAErrorReason {
  // General reasons
  UNDEFINED = 'GE_001',
  INVALID_PARAMETER = 'GE_002',

  // Solver reasons
  SOLVER_ERROR = 'SV_001',
  SOLVER_TERMINATED_UNEXPECTEDLY = 'SV_002',
  SOLVER_NOT_FEASIBLE = 'SV_003',
  SOLVER_SOLUTION_NOT_FOUND = 'SV_004',

  // Plan reasons
  PLAN_RULE_SET_CONFLICT = 'PL_001',

  // Random options reasons
  RANDOM_OPTION_NOT_FOUND = 'RO_001',

  // Metrics data reasons
  METRICS_DATA_NOT_FOUND = 'MD_001',

  // Opening hours reasons
  OPENING_HOURS_NOT_FOUND = 'OH_001',

  // Rule Set reasons
  RULE_SET_NOT_FOUND = 'RS_001',

  // Rule Set group reasons
  RULE_SET_GROUP_NOT_FOUND = 'RG_001',

  // Shift options reasons
  SHIFT_OPTIONS_NOT_FOUND = 'SH_001',
}

// Error type interface
export interface IErrorType {
  // Code of the error
  reason: UAAErrorReason;
  // Message of the error
  message: string;
  // Context that caused the error
  context?: any;
}

// Api error with reason and e.g. missing fields
export class ApiError {
  // Message of error
  public readonly message: string;
  // Reason of error
  public readonly reason: UAAErrorReason;
  // Additional data provided by the backend
  public readonly context?: any;

  /**
   * Constructor
   * @param error Error type
   */
  constructor(error: IErrorType) {
    this.message = error.message;
    this.reason = error.reason;
    this.context = error.context;
  }
}
