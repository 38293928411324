import { IPauseRule } from './pause-rule.model';

export interface IPauseSystem {
  _id: string;
  /** Displayed name of the pause system */
  name: string;
  /** Whether the system is the default legal pause system */
  default: boolean;
  /** Start time when the pause system should be activated */
  startTime?: number;
  /** Start time when the pause system should be activated */
  endTime?: number;
  /** All pause rules that are associated with this system */
  pauseRules: IPauseRule[];
}
