export class FittingStrategyUrl {
  // Strategy
  public static getStrategies(): string[] {
    return ['strategy'];
  }

  public static getStrategyById(strategyId: string): string[] {
    return ['strategy', strategyId];
  }

  public static createSliceForStrategy(strategyId: string): string[] {
    return ['strategy', strategyId, 'slice'];
  }

  public static deleteSliceOfStrategy(strategyId: string, sliceId: string): string[] {
    return ['strategy', strategyId, 'slice', sliceId];
  }

  // Slice
  public static updateSlice(sliceId: string): string[] {
    return ['slice', sliceId];
  }

  // Deviation score
  public static updateDeviationScore(deviationScoreId: string): string[] {
    return ['deviationScore', deviationScoreId];
  }
}
