export class ConfigurationUrl {
  public static getOpeningHours(): string[] {
    return ['openingHours'];
  }

  public static updateOpeningHours(openingHoursId: string): string[] {
    return ['openingHours', openingHoursId];
  }

  public static getShiftOptions(): string[] {
    return ['shiftOptions'];
  }

  public static updateShiftOptions(shiftOptionsId: string): string[] {
    return ['shiftOptions', shiftOptionsId];
  }

  public static getStaffDayOptions(): string[] {
    return ['staffDayOptions'];
  }

  public static updateStaffDayOptions(staffDayOptionsId: string): string[] {
    return ['staffDayOptions', staffDayOptionsId];
  }

  public static getRandomOptions(): string[] {
    return ['randomOptions'];
  }

  public static updateRandomOptions(randomOptionsId: string): string[] {
    return ['randomOptions', randomOptionsId];
  }

  static getPauseOptions(): string[] {
    return ['pauseOptions'];
  }

  static updatePauseOptions(pauseOptionsId: string): string[] {
    return ['pauseOptions', pauseOptionsId];
  }
}
