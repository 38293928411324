import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

import { UnsubscribeService } from './services';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent extends UnsubscribeService {
  // Saved current title string
  private currentTitle: string[] = [''];

  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        // Only on end or cancel of navigation
        filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel),
        // Inject the activated route
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        // Only primary router outlets will be checked
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        if (event.title) {
          if (Array.isArray(event.title)) {
            this.currentTitle = event.title;
          } else {
            this.currentTitle = [event.title];
          }
        } else {
          this.currentTitle = [];
        }

        this.updatePageTitle();
      });
  }

  /**
   * Updates the page title depending on current page
   */
  private updatePageTitle(): void {
    if (!this.currentTitle || this.currentTitle.length === 0) {
      this.titleService.setTitle('uaa-prototype');
      return;
    }

    this.titleService.setTitle(this.currentTitle.join(' › ') + '⠀–⠀' + 'uaa-prototype');
  }
}
