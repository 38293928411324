import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

// Unsubscribe service, that provides the standard unsubscribe functionality
@Injectable({
  providedIn: 'root',
})
export abstract class UnsubscribeService implements OnDestroy {
  // Unsubscribe subject
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  /**
   * Complete the Subject on destroy
   */
  public ngOnDestroy(): void {
    this.completeSubscriber();
  }

  /**
   * Completes the Subject to unsubscribe all subscribers
   */
  protected completeSubscriber(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
