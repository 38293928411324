import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IPlanParams, IPlanResult, IPlanResultRoster } from 'src/app/models';

import { PlanUrl } from './service-urls';
import { BackendService } from './utility-services/backend.service';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  // Planning result
  private planningResult: BehaviorSubject<IPlanResult | null> =
    new BehaviorSubject<IPlanResult | null>(null);
  // Planning result as observable
  public planningResult$: Observable<IPlanResult | null> = this.planningResult.asObservable();

  // Planning params
  private planningParams: BehaviorSubject<IPlanParams | null> =
    new BehaviorSubject<IPlanParams | null>(null);
  // Planning params as observable
  public planningParams$: Observable<IPlanParams | null> = this.planningParams.asObservable();

  constructor(private backendService: BackendService) {}

  public startPlanning(planParams: IPlanParams): Observable<IPlanResult> {
    this.planningParams.next(planParams);
    return this.backendService
      .put<IPlanResult>({
        url: PlanUrl.startPlanning(),
        body: planParams,
      })
      .pipe(
        map((result) => {
          result.roster = result.roster.map((val) => {
            // The transfer format of the date property is string so we must parse
            // these string values to js Date objects
            val.date = new Date(val.date as unknown as string);
            return val;
          });
          return result;
        }),
      )
      .pipe(tap((result) => this.planningResult.next(result)));
  }

  public startDemandPlanning(
    planParams: IPlanParams,
    setData: boolean = true,
  ): Observable<IPlanResult> {
    if (setData) {
      this.planningParams.next(planParams);
    }
    return this.backendService
      .put<IPlanResultRoster[]>({
        url: PlanUrl.startDemandPlanning(),
        body: planParams,
      })
      .pipe(
        map((result) => ({ roster: result })),
        tap((result) => {
          if (setData) {
            this.planningResult.next(result);
          }
        }),
      );
  }
}
