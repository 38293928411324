import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-uaa-tooltip',
  templateUrl: './uaa-tooltip.component.html',
  styleUrls: ['./uaa-tooltip.component.scss'],
})
export class UaaTooltipComponent {
  @Input() content?: TemplateRef<any>;
}
