import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ApiError } from '../models/api-error.model';

// Interceptor that adds the authentication header to request
@Injectable()
export class RouteInterceptor implements HttpInterceptor {
  /**
   * Intercepts the request and adds the authentication header
   * @param req Request
   * @param next HttpHandler
   */
  // tslint:disable-next-line:no-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.charAt(0) === '$') {
      // Add backend url and remove '$' sign
      const routeReq = req.clone({
        url: environment.apiUrl + '/' + req.url.substring(1),
      });

      return next.handle(routeReq).pipe(
        catchError((error) => {
          return this.handleError(error);
        }),
      );
    }

    // If it's another request pass original request
    return next.handle(req).pipe(
      catchError((error) => {
        return this.handleError(error);
      }),
    );
  }

  private handleError(error: any): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse && error.status !== 200) {
      return throwError(new ApiError(error.error.error));
    } else {
      return throwError(error);
    }
  }
}
