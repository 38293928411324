export class MetricsDataUrl {
  public static createMetricsData(): string[] {
    return ['metricsData'];
  }

  public static getMetricsData(): string[] {
    return ['metricsData'];
  }

  public static updateMetricsData(metricsDataId: string): string[] {
    return ['metricsData', metricsDataId];
  }

  public static generateRandomData(): string[] {
    return ['metricsData', 'random'];
  }

  public static deleteMetricsData(): string[] {
    return ['metricsData'];
  }
}
