import { MetricsGranularity, MetricsType } from './metrics-data.model';

export interface IRuleSet {
  _id: string;
  /** Displayed name of the rule set */
  name: string;
  // Metrics Type used in the rule set
  type: MetricsType;
  // Granularity belonging the the metric
  granularity: MetricsGranularity;
  // Day the rule should be applied for
  day?: number;
  // Start time when the rule should hold
  startTime?: number;
  // End time until the rule should hold
  endTime?: number;
  // Metric data from when the rule should be applied
  metricsFrom?: number;
  // Metric data until the rule should be applied
  metricsTo?: number;
  // Amount of ideal staff needed to fit the metric data
  staffIdeal: number;
  // Amount of minimum staff needed to fit the metric data
  staffMin: number;
}
