import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Angular Modules
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';

// Components
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { UaaTabBarComponent } from './components/uaa-tab-bar/uaa-tab-bar.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { UaaTooltipComponent } from './components/uaa-tooltip/uaa-tooltip.component';
import { UaaTooltipDirective } from './components/uaa-tooltip/uaa-tooltip.directive';
import { UaaDemandChartComponent } from './components/uaa-demand-chart/uaa-demand-chart.component';

// List of components that should be provided
const UAA_COMPONENTS = [
  ConfirmDialogComponent,
  UaaTabBarComponent,
  PageContentComponent,
  UaaTooltipComponent,
  UaaDemandChartComponent,
];

// List of directives that should be provided
const UAA_DIRECTIVES = [UaaTooltipDirective];

// List of modules that should be provided
const MATERIAL_MODULES = [
  MatTabsModule,
  MatSlideToggleModule,
  MatFormFieldModule,
  MatInputModule,
  MatTableModule,
  MatSelectModule,
  MatButtonModule,
  MatDialogModule,
  MatButtonToggleModule,
  MatIconModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatCardModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ...MATERIAL_MODULES,
    // Please do not add any modules here, but use the arrays above.
  ],
  declarations: [
    ...UAA_COMPONENTS,
    ...UAA_DIRECTIVES,
    // Please do not add any directives, components or pipes here, but use the arrays above.
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...UAA_COMPONENTS,
    ...UAA_DIRECTIVES,
    ...MATERIAL_MODULES,
    // Please do not add any modules, directives, components or pipes here, but use the arrays above.
  ],
})
export class SharedModule {}
