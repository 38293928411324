import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

/*
  Auth Guard, that prevents unauthorized access to restricted page
 */
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(protected router: Router, protected authService: AuthService) {}

  /**
   * Checks if user is allowed to visit the page
   * @returns true if user can access the page
   */
  protected async checkActivation(): Promise<boolean> {
    return this.authService.isAuthenticated();
  }

  /**
   * Checks if the user can access the page
   * @returns true if user can access the page
   */
  public async canActivate(): Promise<boolean> {
    return this.checkActivation();
  }

  /**
   * Checks if the user can access the page
   * @returns true if user can access the page
   */
  public async canActivateChild(): Promise<boolean> {
    return this.checkActivation();
  }
}
