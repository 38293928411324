import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { appRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { RouteInterceptor } from './interceptors';
import { SharedModule } from './shared/shared.module';
import { GuardsModule } from './guards/guards.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    appRoutes,
    NoopAnimationsModule,
    SharedModule,
    GuardsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RouteInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
