import { Component, Input, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { startWith, takeUntil } from 'rxjs/operators';

import { UnsubscribeService } from 'src/app/services';

export interface ITabItem {
  label: string;
  link: string;
  active?: boolean;
}

/*
  Displays tab bar and handles the navigation
 */
@Component({
  selector: 'app-uaa-tab-bar',
  templateUrl: './uaa-tab-bar.component.html',
  styleUrls: ['./uaa-tab-bar.component.scss'],
})
export class UaaTabBarComponent extends UnsubscribeService implements OnInit {
  // All links that should be displayed in the tab
  @Input()
  public tabBarItems: ITabItem[] = [];

  public constructor(private router: Router) {
    super();
  }

  public ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        // Because ngOnInit is triggered only after initial routing, we have to trigger it manually the first time
        startWith('start'),
      )
      .subscribe((event) => {
        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event === 'start'
        ) {
          this.setActiveNavItem();
        }
      });
  }

  /**
   * Change tab and handle navigation
   * @param link clicked link
   */
  public changeTab(link: string): void {
    this.router.navigateByUrl(link);
  }

  /**
   * Set active item depending on current url
   */
  private setActiveNavItem(): void {
    // Remove query params from url
    const url = this.router.url.split('?')[0] + '/';

    for (const item of this.tabBarItems) {
      item.active = url.indexOf(`${item.link}/`) === 0;
    }
  }
}
