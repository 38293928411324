<h2 *ngIf="data.headline" mat-dialog-title class="uaa-confirm-dialog__title">
  {{ data.headline }}
</h2>

<p *ngFor="let paragraph of data.content" class="uaa-config-dialog__content">{{ paragraph }}</p>

<mat-dialog-actions>
  <button mat-button mat-dialog-close [style.margin-left]="'auto'">
    {{ data.closeButtonText || 'Abbrechen' }}
  </button>
  <button mat-button [mat-dialog-close]="true">{{ data.confirmButtonText || 'Ok' }}</button>
</mat-dialog-actions>
