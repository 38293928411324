import { Component } from '@angular/core';

/*
  Simple page content component, that adopts the default style
 */
@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
})
export class PageContentComponent {}
