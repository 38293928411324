export enum ShiftGranularity {
  FIFTEEN = 15,
  THIRTY = 30,
  SIXTY = 60,
}

export interface IShiftOptions {
  _id: string;
  // Minimal length of shift in hours
  minLength: number;
  // Maximal length of shift in hours
  maxLength: number;
}
