<nav mat-tab-nav-bar>
  <a
    mat-tab-link
    *ngFor="let link of tabBarItems"
    [active]="link.active"
    (click)="changeTab(link.link)"
  >
    {{ link.label }}
  </a>
</nav>
