import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class TimeConversionService {
  /**
   * Convert integer time e.g 1020 into a string like '17:00'
   * @param time Time integer to convert
   */
  public static convertToString(time?: number): string {
    if (time === undefined || time < 0) {
      return '';
    }

    const hours = Math.floor(time / 60);
    const minutes = time % 60;

    const hourString = hours > 9 ? hours.toFixed(0) : `0${hours}`;
    const minuteString = minutes > 9 ? minutes.toFixed(0) : `0${minutes}`;

    return hourString + ':' + minuteString;
  }

  /**
   * Convert string time e.g '17:00' into an integer
   * @param time Time string to convert
   */
  public static convertToNumber(time?: string, inHours?: boolean): number {
    if (!time) {
      return 0;
    }

    const timeArray = time.split(':');

    const hours = +timeArray[0];
    const minutes = +timeArray[1];

    return inHours ? (hours * 60 + minutes) / 60 : hours * 60 + minutes;
  }

  /**
   * JavaScript Date object weekdays are 0 indexed from sunday whereas our data
   * has been structured in a way that mondays are 0 instead of sundays. Returns
   * the correct index.
   */
  public static getWeekday(date: Date): number {
    return (date.getDay() + 6) % 7;
  }
}
