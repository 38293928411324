import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { BackendService } from './utility-services/backend.service';
import { AuthUrl } from './service-urls/auth.service.url';

/*
  Service that handles the authorization of users
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  private hasPassword: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasPassword$: Observable<boolean> = this.hasPassword.asObservable();

  constructor(private cookieService: CookieService, private backendService: BackendService) {
    // Check whether password in the cookies is correct and verify with the backend
    if (this.cookieService.check('pw')) {
      const cookiePassword = this.cookieService.get('pw');
      // Check whether password in the cookies is correct and verify with the backend
      this.enterPassword(cookiePassword).catch(() =>
        console.error(`Wrong password stored in cookie: "${cookiePassword}"`),
      );
    }
  }

  /**
   * Resolves with true given the password has been verified by backend.
   */
  public async isAuthenticated(): Promise<boolean> {
    // data has previously been loaded already
    if (this.hasPassword.value === true) {
      return true;
    }

    // password has not been stored to cookie
    if (!this.cookieService.check('pw')) {
      return false;
    }

    // verify password with backend given it has not already been verified
    try {
      await this.enterPassword(this.cookieService.get('pw'));
      return true;
    } catch (error) {
      console.error(`Incorrect password.`);
      return false;
    }
  }

  /**
   * Enter the password
   * @param password Password
   */
  public enterPassword(password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.backendService
        .post<boolean>({
          url: AuthUrl.enterPassword(),
          body: {
            password,
          },
        })
        .subscribe(
          (response) => {
            if (response === false) {
              this.hasPassword.next(false);
              reject();
            }

            if (response === true) {
              this.cookieService.set('pw', password, undefined, '/');
              this.hasPassword.next(true);
              this.backendService.setPassword(password);
              resolve();
            }
          },
          () => {
            this.hasPassword.next(false);
            this.backendService.setPassword(null);
            reject();
          },
        );
    });
  }
}
