import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IOpeningHours, IRandomOptions, IShiftOptions } from '../models';
import { ConfigurationUrl } from './service-urls';
import { BackendService } from './utility-services/backend.service';
import { IStaffDayOptions } from '../models/staff-day-options.model';
import { IPauseOptions } from '../models/pause-options.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private openingHours: BehaviorSubject<IOpeningHours[] | null> = new BehaviorSubject<
    IOpeningHours[] | null
  >(null);
  public openingHours$: Observable<IOpeningHours[] | null> = this.openingHours.asObservable();

  constructor(private backendService: BackendService) {}

  // opening hours
  public getOpeningHours(): Observable<IOpeningHours[]> {
    const observable = this.backendService.get<IOpeningHours[]>({
      url: ConfigurationUrl.getOpeningHours(),
    });
    observable.subscribe((openingHours) => {
      this.openingHours.next(openingHours);
    });
    return observable;
  }

  public updateOpeningHours(
    openingHoursId: string,
    updateValues: Partial<IOpeningHours>,
  ): Observable<IOpeningHours> {
    return this.backendService.put<IOpeningHours>({
      url: ConfigurationUrl.updateOpeningHours(openingHoursId),
      body: updateValues,
    });
  }

  // shift options
  public getShiftOptions(): Observable<IShiftOptions[]> {
    return this.backendService.get<IShiftOptions[]>({
      url: ConfigurationUrl.getShiftOptions(),
    });
  }

  public updateShiftOptions(
    shiftOptionsId: string,
    updateValues: Partial<IShiftOptions>,
  ): Observable<IShiftOptions> {
    return this.backendService.put<IShiftOptions>({
      url: ConfigurationUrl.updateShiftOptions(shiftOptionsId),
      body: updateValues,
    });
  }

  // staff day options
  public getStaffDayOptions(): Observable<IStaffDayOptions[]> {
    return this.backendService.get<IStaffDayOptions[]>({
      url: ConfigurationUrl.getStaffDayOptions(),
    });
  }

  public updateStaffDayOptions(
    staffDayOptionsId: string,
    updateValues: Partial<IStaffDayOptions>,
  ): Observable<IStaffDayOptions> {
    return this.backendService.put<IStaffDayOptions>({
      url: ConfigurationUrl.updateStaffDayOptions(staffDayOptionsId),
      body: updateValues,
    });
  }

  // random options
  public getRandomOptions(): Observable<IRandomOptions[]> {
    return this.backendService.get<IRandomOptions[]>({
      url: ConfigurationUrl.getRandomOptions(),
    });
  }

  public updateRandomOptions(
    randomOptionsId: string,
    updateValues: Partial<IRandomOptions>,
  ): Observable<IRandomOptions> {
    return this.backendService.put<IRandomOptions>({
      url: ConfigurationUrl.updateRandomOptions(randomOptionsId),
      body: updateValues,
    });
  }

  // pause options

  public getPauseOptions(): Observable<IPauseOptions[]> {
    return this.backendService.get<IPauseOptions[]>({ url: ConfigurationUrl.getPauseOptions() });
  }

  public updatePauseOptions(
    pauseOptionsId: string,
    updateValues: Partial<IPauseOptions>,
  ): Observable<IPauseOptions> {
    return this.backendService.put<IPauseOptions>({
      url: ConfigurationUrl.updatePauseOptions(pauseOptionsId),
      body: updateValues,
    });
  }

  /**
   * Returns an IOpeningHours object for the day index.
   */
  public getOpeningHoursForDay(day: number): IOpeningHours {
    // There are no opening hours for days outside of week boundaries
    if (day < 0 || day > 6) {
      throw new Error(`Parameter day is not in valid range (0-6).`);
    }

    if (!this.openingHours.value) {
      throw new Error(`No opening hours data has been fetched yet.`);
    }

    const item = this.openingHours.value.find((openingHourDay) => openingHourDay.day === day);
    if (!item) {
      throw new Error(`No opening hours for day '${day}' found.`);
    }

    return item;
  }
}
