export interface IPauseOptions {
  _id: string;
  /** Number of pauses each employee should have in a shift */
  pauseCount: number;
  /** Minimal length of a pause in minutes */
  minLength: number;
  /** Maximal length of a pause in minutes */
  maxLength: number;
  /** Gap of a pause to the start of the shift in minutes */
  minGapShiftStart: number;
  /** Gap of a pause to the end of the shift in minutes */
  minGapShiftEnd: number;
  /** Gap of the pauses among themselves in minutes */
  minGapAmongThem: number;
}
