import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { cloneDeep, maxBy, minBy, sortBy } from 'lodash';
import * as moment from 'moment';
import {
  DeviationScoreApplier,
  IDemandResult,
  IFittingSlice,
  IFittingStrategy,
  IPlanResultRoster,
} from 'src/app/models';
import { FittingStrategyService, TimeConversionService } from 'src/app/services';

@Component({
  selector: 'app-uaa-demand-chart',
  templateUrl: './uaa-demand-chart.component.html',
  styleUrls: ['./uaa-demand-chart.component.scss'],
})
export class UaaDemandChartComponent implements OnInit, OnChanges {
  // Non-view-specific data format as input
  @Input() public demandData: IPlanResultRoster[] = [];
  // Data of applied fitting strategy
  @Input() public fittingStrategyData?: IFittingStrategy;
  // Chart height in pixels
  @Input() public graphHeight = 250;
  // Column width
  @Input() public colWidth = 96;
  // Chart header containing the times for each data column
  @Input() public chartHeaderHeight = 64;

  // Currently selected slice
  public selectedSlice: IViewDaySlice | null = null;
  public sliceColor = '#222222';
  public sliceBorderColor = '#555555';
  public sliceBorderWidth = 3;

  public columnsToDisplay = ['deviation', 'weight'];

  // Styling of deviationScore weight indicator
  public deviationScoreWeightIndicatorHeight = 32;

  // View specific representation of input data, enhanced with several properties
  public viewDemandData: IViewDayDemand[] = [];

  // Bar graph styling
  public barWidth = 7;
  public barCornerRadius = 3;
  private colors = {
    underIdeal: '#333333', // '#75AEDA',
    exactlyIdeal: '#73C8B1',
    overIdeal: '#333333', // '#EAC15F',
  };

  // Line graph styling
  public lineColor = `#73C8B1`;
  public lineStrokeWidth = 5;
  public linePointColor = `#3D776A`;
  public linePointSize = 7;

  // General styling settings
  // Automatically filled property representing the full canvas width
  public graphWidth = 0;
  // Date columns separating days are wider than normal columns by this factor
  public dateColumnWidthMultiplier = 1.75;
  // Row divider line hex color string
  public rowLineColor = '#F9FBFD';
  // Row divider line size
  public rowLineSize = 2;
  // Font size used in the svg
  public fontSize = 16;
  // Chart header background color
  public chartHeaderColor = `#F9FBFD`;
  // Chart header margin
  public chartHeaderMargin = 16;
  // Y Axis margin
  public yAxisMargin = 64;
  // Column separation line color as hex string
  public colLineColor = `#E1EBF2`;
  // Column line size
  public colLineSize = 2;

  // General data (mostly calculated based on general style settings)
  // Maximal value on y axis
  public yMax = 0;
  // Maximal value on x axis
  public xMax = 1;
  // An array to save all label texts displayed on y axis
  public yScale: number[] = [];
  // Horizontal margin from yScale to the graph
  public yScaleHorizontalMargin = 32;
  // Represents one unit on the graphs scale on y axis
  public yFraction = 0;
  /**
   * Used to scale the visualization down on y axis so that e.g. labels to data
   * points still fit on the canvas.
   */
  public yFractionMultiplicator = 0.75;

  ngOnInit(): void {
    // TODO: Remove this for production use and switch to e.g. date-fns
    moment.locale('de');

    let totalDataPoints = 0;

    // In order to calculate how much each of the days is being translated on
    // x-axis we need to know how many data points the previous day actually
    // had (since the days could theoretically have different amounts of data
    // points). We save the previous' days width on x-axis temporarily for each
    // loop cycle in this variable.
    let totalXAxisDayOffset = 0;

    /**
     * Before building the visualization relevant dataset we want to make sure
     * we get the maximum values to be drawn in the chart (over all days included)
     * in the dataset so that all data points are drawn correctly.
     */
    for (const viewDay of this.demandData) {
      // Find maxima in the chart
      const demandClone = cloneDeep(viewDay.demand);
      const idealMaximum = maxBy(demandClone, (result) => result.ideal) || { ideal: 0 };
      const actualMaximum = maxBy(demandClone, (result) => result.actual) || { actual: 0 };

      if (this.yMax <= idealMaximum.ideal) {
        this.yMax = idealMaximum.ideal;
      }
      if (this.yMax <= actualMaximum.actual) {
        this.yMax = actualMaximum.actual;
      }
      // Since yFraction directly depends on the yMax we need to recalculate this
      // value
      this.yFraction = (this.graphHeight - this.chartHeaderHeight) / this.yMax;
    }

    /**
     * Iterate over days and assign view relevant data.
     */
    this.viewDemandData = this.demandData.map((day): IViewDayDemand => {
      const viewDay: IViewDayDemand = {
        date: day.date,
        demand: day.demand as IViewDemand[],
        shifts: day.shifts,
        weekdayIndex: TimeConversionService.getWeekday(day.date),
        localizedWeekday: moment(day.date).format('dddd'),
      };

      // The date display should take up one column exactly therefore we add
      // one to the data point counter here
      totalDataPoints += this.dateColumnWidthMultiplier;
      const dataPointsInDay = day.demand.length;
      totalDataPoints += dataPointsInDay;

      viewDay.totalWidth = (day.demand.length + this.dateColumnWidthMultiplier) * this.colWidth;
      viewDay.xAxisDayOffset = totalXAxisDayOffset;
      totalXAxisDayOffset += viewDay.totalWidth;

      // Format the date string. Notice we don't make use of getUTCDate as this
      // would not get the date for the current timezone of the date
      // (e.g. 2021-04-22T22:00:00Z would be 22 instead of 23 which we want)
      // TODO: validate this logic
      viewDay.dateString = this.getNonLocaleDateString(day.date);

      const granularity = this.getGranularityAsNumber(day.demand);
      const firstDemandSlotTime = day.demand[0].slotTime;

      /**
       * A viewDays slices should be available for the view to display easily
       */
      if (this.fittingStrategyData) {
        viewDay.slices = this.fittingStrategyData.slices.filter(
          (slice) => slice.day === viewDay.weekdayIndex,
        );
        viewDay.slices = viewDay.slices.map((slice, index) => {
          const viewDaySlice: IViewDaySlice = slice;
          const sliceStartIndex = (slice.startTime - firstDemandSlotTime) / granularity;
          const sliceEndIndex = (slice.endTime - firstDemandSlotTime) / granularity;
          viewDaySlice.x = this.colWidth * sliceStartIndex;
          viewDaySlice.width = this.colWidth * (sliceEndIndex - sliceStartIndex);
          viewDaySlice.isLastInDay = false;
          viewDaySlice.startTimeString = TimeConversionService.convertToString(slice.startTime);
          viewDaySlice.endTimeString = TimeConversionService.convertToString(slice.endTime);
          viewDaySlice.name = `Slice #${index + 1}`;
          viewDaySlice.weekday = viewDay.localizedWeekday;

          // The last slices endIndex must be incremented by one because its
          // endTime does include the last slot
          if (index + 1 === viewDay.slices?.length) {
            viewDaySlice.width = this.colWidth * (sliceEndIndex + 1 - sliceStartIndex);
            viewDaySlice.isLastInDay = true;
          }
          return viewDaySlice;
        });
      }

      viewDay.linePointsString = ``;
      // Prepare the data points for visualization
      viewDay.demand = viewDay.demand.map((d, index) => {
        const difference = d.actual - d.ideal;
        d.difference = difference;

        d.barColor = this.getBarColor(difference);
        d.differenceLabel = this.getDifferenceLabelText(difference);

        /**
         * Builds up on the string required to draw an SVG polyline.
         * The format of a polyline is something like:
         * 0, 2
         * 10, 3
         * 20, 4
         */
        const xCoordinate = index * this.colWidth + this.barWidth / 2;
        const yCoordinate =
          this.graphHeight - d.ideal * this.yFraction * this.yFractionMultiplicator;
        viewDay.linePointsString += `${xCoordinate},${yCoordinate}\n`;

        d.slotTimeString = TimeConversionService.convertToString(d.slotTime);

        if (viewDay.slices) {
          d.weightApplied = this.findAppliedWeightForSlot(d.slotTime, d.difference, viewDay.slices);

          // Only if the weight applied for the deviation is penalized (in
          // other words is a negative number) a warning below the graph should
          // be shown
          d.showDeviationScoreWarning = d.weightApplied < 0;
        }

        return d;
      });

      /**
       * The column divider lines are drawn exactly once more than we have
       * entries in the demand array as we need to draw boundaries around the
       * actual value columns e.g. | 1 | 2 | 3 | <-- we have 4 '|' characters
       * but only three values.
       */
      viewDay.columnDividerArray = new Array(viewDay.demand.length + 1);

      return viewDay;
    });

    // Build array of y scale numbers
    for (let i = 0; i <= this.yMax; i++) {
      this.yScale.push(i);
    }

    // Total width of chart can only be calculated once the total data point count
    // spread throughout the days is known.
    this.graphWidth = totalDataPoints * this.colWidth - this.colWidth / 2 + this.yAxisMargin;
  }
  // The component should be updated after every parameter change
  ngOnChanges(changes: SimpleChanges): void {
    const demandData = changes.demandData?.currentValue;
    const fittingStrategyData = changes.fittingStrategyData?.currentValue;
    if (demandData != null) {
      this.demandData = demandData;
    }
    if (fittingStrategyData != null) {
      this.fittingStrategyData = fittingStrategyData;
    }
    this.ngOnInit();
  }

  /**
   * Returns a date string in YYYY-MM-DD format – will respect the timezone e.g.
   * if the datetime string was '2021-04-12T23:00:00+02:00' the date printed will
   * be '2021-04-12' instead of '2021-04-13'
   */
  private getNonLocaleDateString(utcDate: Date): string {
    const year = utcDate.getFullYear();
    const month =
      utcDate.getMonth() + 1 < 10 ? '0' + (utcDate.getMonth() + 1) : utcDate.getMonth() + 1;
    const date = utcDate.getDate() < 10 ? '0' + utcDate.getDate() : utcDate.getDate();

    return `${year}-${month}-${date}`;
  }

  /**
   * Returns the label text used inside the graph view when there's a difference
   * between the ideal and the actual value.
   */
  private getDifferenceLabelText(difference: number): string | undefined {
    if (difference === 0) {
      return;
    }

    if (difference > 0) {
      return '+' + difference.toString();
    }

    return difference.toString();
  }

  /**
   * Returns hex color code based on difference of ideal vs. actual value.
   */
  private getBarColor(difference: number): string {
    if (difference === 0) {
      return this.colors.exactlyIdeal;
    } else if (difference > 0) {
      return this.colors.underIdeal;
    } else {
      return this.colors.overIdeal;
    }
  }

  /**
   * Returns the applied weight (deviationScore.weight) for specific slotTime and difference
   */
  private findAppliedWeightForSlot(
    slotTime: number,
    difference: number,
    slices: IFittingSlice[],
  ): number {
    const relevantSlice = slices.find(
      (slice) => slice.startTime <= slotTime && slice.endTime > slotTime,
    );
    if (!relevantSlice) {
      // Assume a 0 - weight given there is no slice relevant to this timeframe
      return 0;
    }

    const appliedDeviationScore = relevantSlice.deviationScores.find((deviationScore) => {
      if (
        deviationScore.operator === DeviationScoreApplier.EQUALS &&
        deviationScore.demandFitting === difference
      ) {
        return true;
      }

      if (
        deviationScore.operator === DeviationScoreApplier.GREATER_THAN &&
        deviationScore.demandFitting < difference
      ) {
        return true;
      }

      if (
        deviationScore.operator === DeviationScoreApplier.LOWER_THAN &&
        deviationScore.demandFitting > difference
      ) {
        return true;
      }

      return false;
    });
    if (!appliedDeviationScore) {
      throw new Error(`Could not find a deviationScore matching the difference`);
    }

    return appliedDeviationScore.weight;
  }

  /**
   * Returns a color string associated to a fittingStrategyDeviationScore weight
   */
  public mapAppliedWeightToColor(appliedWeight: number): string {
    const weightColorOptions = FittingStrategyService.getDeviationScoreColorMapping();
    const weightColorOption = weightColorOptions.find((option) => option.weight === appliedWeight);
    if (!weightColorOption) {
      throw new Error(`Could not find weightColorOption`);
    }

    return weightColorOption.color;
  }

  /**
   * Selects a given slice for detail view
   */
  public toggleSelectSlice(slice: IViewDaySlice, indexOfSlice: number, weekday: string): void {
    if (this.selectedSlice === slice) {
      this.selectedSlice = null;
      return;
    }
    this.selectedSlice = slice;
  }

  /**
   * Returns a number representing the granularity of the metric e.g. 30min /
   * 60 min slotTime. Useful for calculating the width and x positions of
   * elements in view based on their timeSlot.
   */
  private getGranularityAsNumber(demandArr: IDemandResult[]): number {
    // We can't build a difference between two data points if there aren't at
    // least two
    if (demandArr.length < 2) {
      throw new Error(`Not enough demand data points available to infer granularity.`);
    }

    /**
     * Assuming a sorted array of demand we can calculate the granularty by
     * taking the difference of slotTime between two adjacent data points e.g.
     * [
     *  { slotTime: 480, ... }, // index 0
     *  { slotTime: 540, ... }, // index 1
     *  ...
     * ]
     * Therefore: 540 - 480 = 60
     */
    const sortedDemandArr = sortBy(demandArr, (demand) => demand.slotTime);
    return sortedDemandArr[1].slotTime - sortedDemandArr[0].slotTime;
  }

  /**
   * Returns the correct sign as a string of a DeviationScoreApplier e.g.
   * 'GREATHER_THAN' as input returns '>'
   */
  public mapOperatorToSign(operator: DeviationScoreApplier): string {
    if (operator === DeviationScoreApplier.GREATER_THAN) {
      return '>';
    }

    if (operator === DeviationScoreApplier.LOWER_THAN) {
      return '<';
    }

    return '';
  }
}

interface IViewDayDemand extends IPlanResultRoster {
  // Overwrite demand to be of type IViewDemand
  demand: IViewDemand[];

  // The following attributes are included for easy of use in template
  totalWidth?: number;
  xAxisDayOffset?: number;
  dateString?: string;
  linePointsString?: string;
  columnDividerArray?: number[];
  weekdayIndex?: number;
  localizedWeekday?: string;
  slices?: IViewDaySlice[];
}

interface IViewDemand extends IDemandResult {
  // Overwrite max attribute of IDemandResult interface
  max: number | null;

  // Following attributes are only present for ease of use in template
  differenceLabel?: string;
  barColor?: string;
  difference?: number;
  slotTimeString?: string;
  weightApplied?: number;
  // If deviation is in penalized range show warning
  showDeviationScoreWarning?: boolean;
}

interface IViewDaySlice extends IFittingSlice {
  // Following attributes are only present for ease of use in template
  width?: number;
  x?: number;
  isLastInDay?: boolean;
  startTimeString?: string;
  endTimeString?: string;
  weekday?: string;
  name?: string;
}
